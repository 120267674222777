<template>
  <span v-if="computer.customer">
    <b-icon-briefcase-fill v-if="computer.customer.usertype === 1" class="mr-2" :id="'tt-user-' + computer.id"
                           :variant="iconVariantCustomer"/>
    <b-icon-person-fill v-else class="mr-2" :id="'tt-user-' + computer.id"
                        :variant="iconVariantCustomer"/>
    <b-tooltip :target="'tt-user-' + computer.id" custom-class="tooltip-width-100" triggers="hover">
      <div class="text-left">
        {{ computer.customer.zuname }}, {{ computer.customer.vorname }} ({{ computer.customer.kdnr }})<br>
        {{ computer.customer.kurs }}<br>
        Klassenraum-{{ computer.customer.intklr }}
      </div>
    </b-tooltip>
  </span>
  <span v-else-if="computer.customer_rpc">
    <b-icon-display-fill class="mr-2" :variant="iconVariantCustomerRPC" :id="'tt-user-' + computer.id"/>
    <b-tooltip :target="'tt-user-' + computer.id" custom-class="tooltip-width-100" triggers="hover">
      <div class="text-left">
        {{ computer.customer_rpc.zuname }}, {{ computer.customer_rpc.vorname }} ({{ computer.customer_rpc.kdnr }})<br>
        {{ computer.customer_rpc.kurs }}<br>
        Klassenraum-{{ computer.customer_rpc.intklr }}
      </div>
    </b-tooltip>
  </span>
  <span v-else>
    <b-icon-person-fill class="mr-2" :variant="iconVariant" :id="'icon-' + computer.id"/>
  </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UserConnected",
  props: ['computer'],
  computed: {
    ...mapGetters(['userColorAccessibility']),
    iconVariant() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    },
    iconVariantCustomer() {
      if(this.userColorAccessibility === 'red-green') {
        return this.computer.customer.active ? 'success-deuteranopia' : 'danger-deuteranopia'
      }
      else {
        return this.computer.customer.active ? 'success' : 'danger'
      }
    },
    iconVariantCustomerRPC() {
      if(this.userColorAccessibility === 'red-green') { return 'warning-deuteranopia' }
      return 'warning'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
