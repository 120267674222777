<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="1"/>
        <b-col cols="10">
          <SubHeader>
            <b-row class="mt-2 mb-1">
              <b-col cols="8" class="mr-auto">
                <b-row>
                  <b-col cols="auto">
                    <small class="ml-2">Letzte Aktualisierung: {{ lastDataFetched }}</small>
                  </b-col>
                  <b-col cols="4">
                    <b-form-input v-model="search" debounce="500" size="sm" placeholder="Suche nach Hostname..."/>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </SubHeader>
          <div v-if="groupsFiltered">
            <div v-for="group in groupsFiltered" :key="group.id">
              <Group :group="group" class="mt-3">
                <template #group-content>
                  <div v-for="computer in computers(group)" :key="computer.id">
                    <VPNRow :computer="computer" @refresh="refresh"/>
                  </div>
                </template>
              </Group>
            </div>
          </div>
          <ScrollToTop/>
        </b-col>
        <b-col cols="1"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import {mapActions} from "vuex";
import {getRequest} from "@/modules/requests";
import Group from "@/components/lists/Group";
import VPNRow from "@/components/lists/vpnList/VPNRow";
import SubHeader from "@/components/structure/SubHeader";
import ScrollToTop from "@/components/utility/ScrollToTop";

export default {
  name: "VPNList",
  components: {
    Group,
    VPNRow,
    SubHeader,
    ScrollToTop
  },
  data() {
    return {
      search: '',
      groups: null,
      lastDataFetched: '-'
    }
  },
  methods: {
    ...mapActions(['setLoadingState']),
    async getVPNs() {
      await getRequest('vpnList', null, this)
          .then((response) => {
            this.groups = response.data
          })
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getVPNs()
      this.lastDataFetched = dayjs().format('HH:mm:ss')
      this.setLoadingState(false)
    },
    computers(group) {
      return _.filter(group.computers, (computer) => {
        return computer.hostname.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  computed: {
    groupsFiltered() {
      return _.filter(this.groups, (group) => {
        return _.filter(group.computers, (computer) => {
          return computer.hostname.toLowerCase().includes(this.search.toLowerCase())
        }).length > 0
      })
    }
  },
  created() {
    this.refresh();
  }
}
</script>

<style lang="scss" scoped>
</style>
