<template>
  <span class="clickable badge-control" :title="title" v-clipboard:copy="value" v-clipboard:success="copySuccess"
        v-clipboard:error="copyError">
    {{ value }}
  </span>
</template>

<script>
import {toastDanger, toastInfo} from "@/modules/status";

export default {
  name: "Clipboard",
  props: ['value', 'title'],
  methods: {
    copySuccess() {
      toastInfo(this, 'Kopieren erfolgreich!')
    },
    copyError() {
      toastDanger(this, 'Kopieren fehlgeschlagen!')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
