<template>
  <span v-if="computer.online">
    <b-icon-arrow-up-circle-fill class="mr-2" :variant="iconVariantSuccess" :id="'tt-status-' + computer.id"/>
    <b-tooltip :target="'tt-status-' + computer.id" triggers="hover">Online</b-tooltip>
  </span>
  <span v-else>
    <b-icon-arrow-down-circle-fill class="mr-2" :variant="iconVariantDanger" :id="'tt-status-' + computer.id"/>
    <b-tooltip :target="'tt-status-' + computer.id" triggers="hover">Offline</b-tooltip>
  </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ComputerOnline",
  props: ['computer'],
  computed: {
    ...mapGetters(['userColorAccessibility']),
    iconVariantSuccess() {
      if(this.userColorAccessibility === 'red-green') { return 'success-deuteranopia' }
      return 'success'
    },
    iconVariantDanger() {
      if(this.userColorAccessibility === 'red-green') { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
