<template>
  <b-card body-class="p-0" header-tag="header" class="shadow-sm">
    <template #header>
      <div class="text-center noselect">
        {{ group.name }}
        <b-icon-caret-down-fill class="clickable" @click="expand = !expand" :flip-v="expand"/>
      </div>
    </template>
    <b-card-body v-if="expand" class="pt-3 pb-2">
      <slot name="group-content"/>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  name: "Group",
  props: ['group'],
  data() {
    return {
      expand: true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
